import React from "react";

const ContentSection = () => {
  return (
    <div className="bg-white rounded my-5 m-5 px-4 py-5 mx-auto container">
      <div className="row">
        <div className="headers mt-4">
          <h4 className="title">What See My Clouds is</h4>
          <p className="desc mt-2">
            A dedicated social media platform specific to clouds. Download the
            app. Sign-up. Connect with a community of cloud lovers.{" "}
          </p>
          <div className="mt-3">
            <li>Upload & Share raw cloud pictures</li>
            <li>
              Engage & Discuss meteorology, the weather, and types of clouds
            </li>
            <li>
              Learn & Discover cloud classifications, formations, and fun facts
            </li>
            <li>Connect with similar people </li>
          </div>
        </div>
        <div className="headers mt-4">
          <h4 className="title">Why Join See My Clouds</h4>
          <p className="desc mt-2">
            When you step out of the house and take cloud pictures, appreciate
            the nature, and want to share the beauties of nature with the world
            – See My Clouds is there for you. Download the app and engage with
            people who are on the same page. For science, and for meteorology.{" "}
          </p>
        </div>
        <div className="headers mt-4">
          <h4 className="title">Be Part of a Bigger Story</h4>
          <p className="desc mt-2">
            It’s a growing community. A bigger narrative. Join the conversation
            where cloud enthusiasts, weather experts, and people who generally
            admire the the sky are engaging. Every cloud tells a story, and we
            want to see yours!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContentSection;
