import React from "react";
import { Col, Row } from "react-bootstrap";
import mobileScreen from "../../assets/images/Home/ReadyStarted/Group.svg";
import AppleStore from "../../assets/images/Home/ReadyStarted/Apple.svg";

const ReadyToGetStarted = () => {
  return (
    <div className="ready-to-get-started-component  container">
      <Row>
        <Col
          sm={12}
          md={12}
          lg={7}
          className="d-flex align-items-center col-center"
        >
          <div className="content">
            <h1 style={{ textTransform: "none" }}>Ready to get started?</h1>
            <p className="mt-5">
              Join our See My Clouds community today. It’s for everyone.
              Students. Teachers. Scientists. And anyone who is a cloud lover.
            </p>
            <div className="d-flex justify-content-around cursor mt-5 store_image">
              <a
                href="https://apps.apple.com/us/app/see-my-clouds/id6578448718"
                target="_blank"
              >
                <img
                  src={AppleStore}
                  alt=""
                  className="object-fit-contain image-fluid"
                />
              </a>
            </div>
          </div>
        </Col>
        <Col lg={{ span: 4, offset: 1 }} className="blue-bg d-lg-block d-none">
          <img
            loading="lazy"
            src={mobileScreen}
            className="object-fit-contain image-fluid"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ReadyToGetStarted;
